import './home.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Row, Col, Alert,Table,Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';

export type IHomeProp = StateProps;

export const Home = (props: IHomeProp) => {
  const { account } = props;

  return (
    <Row>
      <Col md="7">

  
        {account && account.login ? (

          <div>
          
          <h2>
          <Translate contentKey="home.title">Welcome, </Translate> {account.firstName} {account.lastName}
          </h2>
          <p className="lead">
          <Translate contentKey="home.subtitle">This is your homepage</Translate>
          </p>

            <Alert color="success">
              <Translate contentKey="home.logged.message" interpolate={{ username: account.login }}>
                You are logged in as user {account.login}.
              </Translate>
              <br/>
              <Translate contentKey="home.logged.visibleJobOrdersNumber" interpolate={{ visibleJobOrdersNumber: account.visibleJobOrders.length }}>
                You have a total of {account.visibleJobOrders.length} Job Orders assigned.
              </Translate>
            </Alert>

            <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to="/ts-entry" color="info" size="lg">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="home.link.tsentry">View</Translate>
                        </span>
                      </Button>
            </div>

          </div>
        ) : (
          <div>

          <h2>
            <Translate contentKey="home.title">Welcome, </Translate> 
          </h2>
          <p className="lead">
            <Translate contentKey="home.subtitlenotlogged">This is the Job Order Control System</Translate>
          </p>
          <p className="lead">
            <Translate contentKey="home.subtitleinvitetolog">Use your credentials to login </Translate>
          </p>


        <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to="/login" color="info" size="lg">
                        <FontAwesomeIcon icon="sign-in" />{' '}
                        <span className="d-none d-md-inline">
                        <Translate contentKey="global.menu.account.login">Sign in</Translate>
                        </span>
                      </Button>
            </div>
          
          </div>
        )}
      
      </Col>

      <Col md="5" className="pad">
        <span className="ebs-logo rounded" />
      </Col>
    </Row>

  );
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Home);
