import { IJobOrderOwner } from 'app/shared/model/job-order-owner.model';
import { IEmployee } from 'app/shared/model/employee.model';

export interface IBusinessUnit {
  id?: number;
  businessUnitName?: string;
  jobOrderOwners?: IJobOrderOwner[];
  employees?: IEmployee[];
}

export const defaultValue: Readonly<IBusinessUnit> = {};
