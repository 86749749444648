import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import JobOrderOwner from './job-order-owner';
import JobOrderOwnerDetail from './job-order-owner-detail';
import JobOrderOwnerUpdate from './job-order-owner-update';
import JobOrderOwnerDeleteDialog from './job-order-owner-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={JobOrderOwnerDeleteDialog} />
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={JobOrderOwnerUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={JobOrderOwnerUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={JobOrderOwnerDetail} />
      <ErrorBoundaryRoute path={match.url} component={JobOrderOwner} />
    </Switch>
  </>
);

export default Routes;
