import { ITSEntry } from 'app/shared/model/ts-entry.model';
import { IBusinessUnit } from 'app/shared/model/business-unit.model';

export interface IEmployee {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  tsEntries?: ITSEntry[];
  businessUnit?: IBusinessUnit;
}

export const defaultValue: Readonly<IEmployee> = {};
