import React  from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Translate } from 'react-jhipster';
import {ACTION_TYPES} from "../ts-entry.reducer";

const ModalBlockRejections = (props) => {
  const {
    classname,
    show
  } = props;

  const dispatch = useDispatch()

  const close = () => dispatch({ type: ACTION_TYPES.HIDE_BLOCK_REJECTION_MODAL})

  return (
    <div id="Modal">
      <Modal isOpen={show} className={classname}>
        <ModalHeader>
        <Translate contentKey="ordersManagementApp.tSEntry.modals.blockRejectionsTitle">Warning</Translate>
        </ModalHeader>
        <ModalBody>
        <Translate contentKey="ordersManagementApp.tSEntry.modals.blockRejectionsMessage">It  &apos; s not possibile to reject more than ten entries at once</Translate>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={close}>OK</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalBlockRejections;