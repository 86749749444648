import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction, logDebug } from 'react-jhipster';
import { MAIL_API_URL } from 'app/config/constants';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ITSEntry, ITSEntryFilter, defaultValue } from 'app/shared/model/ts-entry.model';
import _, { List } from 'lodash';

export const ACTION_TYPES = {
  FETCH_TSENTRY_LIST: 'tSEntry/FETCH_TSENTRY_LIST',
  // FETCH_TSENTRY_SELECT_LIST: 'tSEntry/FETCH_TSENTRY_SELECT_LIST',
  FETCH_TSENTRY: 'tSEntry/FETCH_TSENTRY',
  CREATE_TSENTRY: 'tSEntry/CREATE_TSENTRY',
  UPDATE_TSENTRY: 'tSEntry/UPDATE_TSENTRY',
  DELETE_TSENTRY: 'tSEntry/DELETE_TSENTRY',
  RESET: 'tSEntry/RESET',
  ADD_REJECTED_LIST: 'tSEntry/ADD_REJECTED_LIST',
  ADD_APPROVED_LIST: 'tSEntry/ADD_APPROVED_LIST',
  ADD_SELECTED_LIST: 'tSEntry/ADD_SELECTED_LIST',
  ADD_ALL_SELECTED_LIST: 'tSEntry/ADD_ALL_SELECTED_LIST',
  REMOVE_SELECTED_LIST: 'tSEntry/REMOVE_SELECTED_LIST',
  REMOVE_ALL_SELECTED_LIST: 'tSEntry/REMOVE_ALL_SELECTED_LIST',
  SET_FILTERS_LIST: 'tsEntry/SET_FILTERS_LIST',
  RESET_FILTERS_LIST: 'tsEntry/RESET_FILTERS_LIST',
  SEND_REJECTION_MAIL: 'tsEntry/SEND_REJECTION_MAIL',
  SHOW_BLOCK_REJECTION_MODAL: 'tsEntry/SHOW_BLOCK_REJECTION_MODAL',
  HIDE_BLOCK_REJECTION_MODAL: 'tsEntry/HIDE_BLOCK_REJECTION_MODAL',
  SYNC_TSENTRY: 'tSEntry/SYNC_TSENTRY'
};

const initialState = {
  blockRejectionsModal: false,
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITSEntry>,
  rejectedEntryList: [] as ReadonlyArray<ITSEntry>,
  approvedEntryList: [] as ReadonlyArray<ITSEntry>,
  selectedEntryList: [] as ReadonlyArray<ITSEntry>,
  filterEntryList: {} as ITSEntryFilter,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type TSEntryState = Readonly<typeof initialState>;

// Reducer

export default (state: TSEntryState = initialState, action): TSEntryState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_TSENTRY_LIST):
    case REQUEST(ACTION_TYPES.SYNC_TSENTRY):
    case REQUEST(ACTION_TYPES.FETCH_TSENTRY):
    case REQUEST(ACTION_TYPES.ADD_SELECTED_LIST):
    case REQUEST(ACTION_TYPES.REMOVE_SELECTED_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_TSENTRY):
    case REQUEST(ACTION_TYPES.UPDATE_TSENTRY):
    case REQUEST(ACTION_TYPES.DELETE_TSENTRY):
    case REQUEST(ACTION_TYPES.ADD_REJECTED_LIST):
    case REQUEST(ACTION_TYPES.ADD_APPROVED_LIST):
    case REQUEST(ACTION_TYPES.SEND_REJECTION_MAIL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_TSENTRY_LIST):
    case FAILURE(ACTION_TYPES.SYNC_TSENTRY):
    case FAILURE(ACTION_TYPES.FETCH_TSENTRY):
    case FAILURE(ACTION_TYPES.CREATE_TSENTRY):
    case FAILURE(ACTION_TYPES.UPDATE_TSENTRY):
    case FAILURE(ACTION_TYPES.DELETE_TSENTRY):
    case FAILURE(ACTION_TYPES.ADD_REJECTED_LIST):
    case FAILURE(ACTION_TYPES.ADD_APPROVED_LIST):
    case FAILURE(ACTION_TYPES.ADD_SELECTED_LIST):
    case FAILURE(ACTION_TYPES.REMOVE_SELECTED_LIST):
    case FAILURE(ACTION_TYPES.SEND_REJECTION_MAIL):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_TSENTRY_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_TSENTRY):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_TSENTRY):
    case SUCCESS(ACTION_TYPES.UPDATE_TSENTRY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_TSENTRY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.ADD_APPROVED_LIST:
      return {
        ...state,
        loading: false,
        approvedEntryList: action.payload
      };
    case ACTION_TYPES.ADD_REJECTED_LIST:
      return {
        ...state,
        loading: false,
        rejectedEntryList: action.payload
      };
    case ACTION_TYPES.ADD_SELECTED_LIST:
    case ACTION_TYPES.REMOVE_SELECTED_LIST:
    case ACTION_TYPES.ADD_ALL_SELECTED_LIST:
    case ACTION_TYPES.REMOVE_ALL_SELECTED_LIST:
      return {
        ...state,
        loading: false,
        selectedEntryList: action.payload
      };
    case ACTION_TYPES.SET_FILTERS_LIST:
      return {
        ...state,
        loading: false,
        filterEntryList: action.payload
      };
    case ACTION_TYPES.SHOW_BLOCK_REJECTION_MODAL:
      return {
        ...state,
        loading: false,
        blockRejectionsModal: true
      };
    case ACTION_TYPES.HIDE_BLOCK_REJECTION_MODAL:
      return {
        ...state,
        loading: false,
        blockRejectionsModal: false
      };
    case SUCCESS(ACTION_TYPES.SEND_REJECTION_MAIL):
      return {
        ...state,
        loading: false
      };
    case SUCCESS(ACTION_TYPES.SYNC_TSENTRY): {
      return {
        ...state,
        entities: []
      };
    }

    default:
      return state;
  }
};

const apiUrl = 'api/ts-entries';

// Actions

export const getEntities: ICrudGetAllAction<ITSEntry> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_TSENTRY_LIST,
  payload: axios.get<ITSEntry>(`${apiUrl}/pm?cacheBuster=${new Date().getTime()}`)
});

export const getEntitiesMese = (yearString: string, monthString: string) => {
  return {
    type: ACTION_TYPES.FETCH_TSENTRY_LIST,
    payload: axios.get<ITSEntry>(`${apiUrl}/pm/${yearString}/${monthString}`)
  };
};

export const getEntity: ICrudGetAction<ITSEntry> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_TSENTRY,
    payload: axios.get<ITSEntry>(requestUrl)
  };
};

export const syncTsc: ICrudGetAction<string> = () => {
  const requestUrl = `${apiUrl}/sync`;
  return {
    type: ACTION_TYPES.SYNC_TSENTRY,
    payload: axios.get<string>(requestUrl)
  };
};

export const pingEmail: ICrudGetAction<List<any>> = () => {
  const requestUrl = `${apiUrl}/ping`;
  return {
    type: ACTION_TYPES.SYNC_TSENTRY,
    payload: axios.get<List<any>>(requestUrl)
  };
  
};

export const createEntity: ICrudPutAction<ITSEntry> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TSENTRY,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ITSEntry> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_TSENTRY,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<ITSEntry> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_TSENTRY,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const addRejectedEntity = (entity, rejectedEntryList) => {
  return {
    type: ACTION_TYPES.ADD_REJECTED_LIST,
    payload: [...rejectedEntryList, entity]
  };
};

export const addApprovedEntity = (entity, approvedEntryList) => {
  return {
    type: ACTION_TYPES.ADD_APPROVED_LIST,
    payload: [...approvedEntryList, entity]
  };
};

export const addSelectedEntity = (entity, selectedEntryList) => {
  return {
    type: ACTION_TYPES.ADD_SELECTED_LIST,
    payload: [...selectedEntryList, entity]
  };
};

export const addAllSelectedEntity = selectedEntryList => {
  return {
    type: ACTION_TYPES.ADD_ALL_SELECTED_LIST,
    payload: [...selectedEntryList]
  };
};

export const removeSelectedEntity = (entity, selectedEntryList) => {
  return {
    type: ACTION_TYPES.REMOVE_SELECTED_LIST,
    payload: selectedEntryList.filter(e => e.id !== entity.id)
  };
};

export const removeAllSelectedEntity = selectedEntryList => {
  return {
    type: ACTION_TYPES.REMOVE_ALL_SELECTED_LIST,
    payload: selectedEntryList.filter(e => false)
  };
};

export const setFilterEntryList = (filterSet, filterEntryList) => {
  return {
    type: ACTION_TYPES.SET_FILTERS_LIST,
    payload: { ...filterEntryList, ...filterSet }
  };
};

export const resetFilterEntryList = filterEntryList => {
  return {
    type: ACTION_TYPES.RESET_FILTERS_LIST,
    payload: { ...filterEntryList, ...initialState['filterEntryList'] }
  };
};

export const toggleBlockRejectionsModal = show => {
  return {
    type: show ? ACTION_TYPES.SHOW_BLOCK_REJECTION_MODAL : ACTION_TYPES.HIDE_BLOCK_REJECTION_MODAL,
    payload: { blockRejectionsModal: show }
  };
};

export const sendRejectionMail = entity => {
  //const requestUrl = MAIL_API_URL;
  return {
    type: ACTION_TYPES.SEND_REJECTION_MAIL,
    payload: axios.post(apiUrl+"/inviaMail", cleanEntity(entity))
  };
};

export const startSched = entity => {
  const requestUrl = 'http://51.178.86.65:8881/';
  return {
    type: ACTION_TYPES.SYNC_TSENTRY,
    payload: axios.get(requestUrl, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
      }
    })
  };
};
