import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table, Label, ButtonGroup, FormGroup , Input, Card, CardBody , CardColumns, CardText ,Badge } from 'reactstrap';
import { Translate, TextFormat, translate } from 'react-jhipster';
import {  AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faThumbsUp, faThumbsDown, faCircle, faSquare , faCheckSquare, faTimes  } from "@fortawesome/free-solid-svg-icons";
library.add(faThumbsUp,faThumbsDown, faCircle, faSquare, faCheckSquare, faTimes);
import { IRootState } from 'app/shared/reducers';
import { getEntities, updateEntity, addApprovedEntity, addRejectedEntity, addSelectedEntity, removeSelectedEntity, setFilterEntryList, resetFilterEntryList, removeAllSelectedEntity, addAllSelectedEntity,sendRejectionMail,startSched,toggleBlockRejectionsModal,pingEmail, syncTsc,getEntitiesMese } from './ts-entry.reducer';
import { ITSEntry,ITSEntryFilter,ITSEntryExsport } from 'app/shared/model/ts-entry.model';
import {  APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import {TSEntryStatus} from 'app/shared/model/enumerations/ts-entry-status.model';
import moment from 'moment'
import ModalBlockRejections from  "./components/modal-block-rejections"
import {CSVLink} from 'react-csv';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import 'moment/locale/it';

function getStatusIcon(item){
  switch (item) {
    case "APPROVED":
      return <FontAwesomeIcon icon="thumbs-up" color="green" size="lg"/>
      break;
    case "REJECTED":
      return <FontAwesomeIcon icon="thumbs-down" color="red" size="lg"/>
      break;
    case "NOT_VERIFIED":
      return <FontAwesomeIcon icon="circle" color="orange" size="lg"/>
    default:
       return null;
    break;
    }
}


function getDettaglio(dettaglio){
  let ris;
  if(dettaglio!=null && dettaglio.length>=100){
    ris=dettaglio.substring(0,95)+"...";
    return ris;
  }
  return dettaglio
}
function converterStatus(status){
  let res="";
  switch(status) {
    case 'APPROVED':
      res=translate("ordersManagementApp.tSEntry.cards.approved");
      break;
    case 'NOT_VERIFIED':
      res=translate("ordersManagementApp.tSEntry.cards.toapprove");
      break;
    case 'REJECTED':
      res=translate("ordersManagementApp.tSEntry.cards.rejected");
      break;
    default:
      break;
  }
  return res;
}

function converterMonth(month){
  let res="";
  switch(month) {
    case 'January':
      res=translate("ordersManagementApp.tSEntry.month.january");
      break;
    case 'February':
      res=translate("ordersManagementApp.tSEntry.month.february");
      break;
    case 'March':
      res=translate("ordersManagementApp.tSEntry.month.march");
      break;
    case 'April':
      res=translate("ordersManagementApp.tSEntry.month.april");
      break;
    case 'May':
      res=translate("ordersManagementApp.tSEntry.month.may");
      break;
    case 'June':
      res=translate("ordersManagementApp.tSEntry.month.june");
      break;
    case 'July':
      res=translate("ordersManagementApp.tSEntry.month.july");
      break;
    case 'August':
      res=translate("ordersManagementApp.tSEntry.month.august");
      break;
    case 'September':
      res=translate("ordersManagementApp.tSEntry.month.september");
      break;
    case 'October':
      res=translate("ordersManagementApp.tSEntry.month.october");
      break;
    case 'November':
      res=translate("ordersManagementApp.tSEntry.month.november");
      break;
    case 'December':
      res=translate("ordersManagementApp.tSEntry.month.december");
      break;
    default:
      break;
  }
  return res;
}
function getExsport(tSEntryList){
  const data: ITSEntryExsport[]=[];
 
  tSEntryList.map((tsEntry, i)=>{
    if(tsEntry!==null && tsEntry!==undefined){
      const entryExsport : ITSEntryExsport={ 
        ["giorno"] : "",
        ["ore"] : 0,
        ["dipendente"] :'',
        ["codiceCommessa"] :'',
        ["nomeCommessa"] :'',
        ["dettaglio"] :'',
        ["responsabile"] :'',
        ["status"]:TSEntryStatus.NOT_VERIFIED,
      };
      entryExsport.giorno=tsEntry.day?.toString();
      entryExsport.ore=tsEntry.hours;
      entryExsport.status=tsEntry.status;
      entryExsport.dipendente=tsEntry.employee?.firstName+" "+tsEntry.employee?.lastName;
      entryExsport.nomeCommessa=tsEntry.jobOrder.jobOrderName;
      entryExsport.codiceCommessa=tsEntry.jobOrder.jobOrderCode;
      entryExsport.dettaglio=tsEntry.dettaglio;
      entryExsport.responsabile=tsEntry.jobOrder?.jobOrderOwner?.name+" "+tsEntry.jobOrder?.jobOrderOwner?.surname;
      data.push(entryExsport);
    }
    
  })
  return data;
}


export interface ITSEntryProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const maxAllowedRejectionsAtOnce = 10;

export const TSEntry = (props: ITSEntryProps) => {
  
  

  const { tSEntryList, match, loading,authorities,visibleJobOrders, rejectedEntryList,selectedEntryList,approvedEntryList, filterEntryList, currentLocale, blockRejectionsModal  } = props;
  let  monthSelect  = moment().locale('it').subtract(1, "month").lang("en").format('MMMM');
  let  monthTemp  = moment().locale('it').subtract(1, "month").lang("en").format('MMMM');
  let  yearTemp = moment().locale('it').format('YYYY');
  let  yearSelect = moment().locale('it').format('YYYY');
  const yearCurrent=yearSelect;
  const isAdmin=hasAnyAuthority(authorities,[AUTHORITIES.UPDATETSC]);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  function setYearMonth() {
   
    let daySelect="";
    if(tSEntryList!=null &&tSEntryList.length>0&&tSEntryList[0].day!=null)
        daySelect=tSEntryList[0].day.toString();
  /*  else if(props.dateTemp!="")
      daySelect= props.dateTemp*/
    const year= daySelect.substring(0,4);
    const monthString= (daySelect.substring(5,7));
    if(monthString!==""){
      const monthInt=Number(monthString);
      monthSelect=months[monthInt-1];
      monthTemp=monthSelect;
    }
    if(year!==""){
      yearTemp=year;
      yearSelect=year;
    }
    
  }
  setYearMonth();
  useEffect(() => {
    props.getEntities();
  }, []);

  const updateFilters = ( event  ) =>{
    const { target } = event;
    const filters : ITSEntryFilter  = { [target.name] : target.value }
    props.setFilterEntryList(filters, filterEntryList)

  }
  const [isDisable, setIsDisable] = useState(false);
  const resetAllFilters = (   ) =>{
    const filters : ITSEntryFilter  = { 
      ["dateFrom"] : null,
      ["dateTo"] : null,
      ["employee"] :'',
      ["jobOrder"] :'',
      ["jobOrderOwner"] :'',
      ["status"]:'' 
    }
    props.setFilterEntryList(filters, filterEntryList)

  }
 
  
  
  const years=[
    (Number(yearCurrent)-2),
    (Number(yearCurrent)-1),
    (Number(yearCurrent)),
  ]
  

 

  const updateEntityStatus = (tSEntry:ITSEntry, approved:boolean) => {
    let status ;
    switch(approved){
      case true:
        status = TSEntryStatus.APPROVED;
        break;
      case false:
        status = TSEntryStatus.REJECTED
        break;
      default:
        status = TSEntryStatus.NOT_VERIFIED;
        break;
    }
    props.updateEntity({...tSEntry,status});
  };

  const updateStatusLists = (tSEntry:ITSEntry, approved:boolean) => {

    approved ? props.addApprovedEntity(tSEntry, approvedEntryList) : props.addRejectedEntity(tSEntry, rejectedEntryList);
  }

  const updateSelectedList = (tSEntry:ITSEntry, insert:boolean)=>{
    insert ? props.addSelectedEntity(tSEntry,selectedEntryList) : props.removeSelectedEntity(tSEntry,selectedEntryList)
  }

  const processEntry = (tSEntry:ITSEntry, approved:boolean,index:number) => {
    updateSelectedList(tSEntry,false)
    updateEntityStatus(tSEntry,approved)
    if(!approved) {
      setTimeout(() => {
        sendRejectionMail(tSEntry);
      }, 3000*index); 
      
    }
    tSEntry.status = approved ? TSEntryStatus.APPROVED : TSEntryStatus.REJECTED
    updateStatusLists(tSEntry,approved)
    
    
  }


  const syncEntryTsc = () => { 
    setIsDisable(true);
    syncTsc(null);
    setTimeout(() => {
      setIsDisable(false);
      window.location.reload();
    }, 20000);
    
  }



  const processEntries = (tSEntries:ITSEntry[], approved:boolean) =>{

    approved ?
    (
      tSEntries.filter(e => selectedEntryList.map(entity => entity.id).includes(e.id)).forEach((tSEntry) => processEntry(tSEntry,true,0))
    )
    :
    (
        selectedEntryList.length <= maxAllowedRejectionsAtOnce ?
        tSEntries.filter(e => selectedEntryList.map(entity => entity.id).includes(e.id)).forEach((tSEntry, index) => processEntry(tSEntry,false,index)
      )
        :
        props.toggleBlockRejectionsModal(true)
    );

   if (approved || selectedEntryList.length <= maxAllowedRejectionsAtOnce) props.removeAllSelectedEntity(tSEntries)

  }


  
  const filteredTSEntriesList = tSEntryList ? 
                                tSEntryList.filter(tSEntry => tSEntry.id && tSEntry.jobOrder.jobOrderOwner && visibleJobOrders.includes(tSEntry.jobOrder.id))
                                .filter(tSEntry => (filterEntryList.jobOrder && filterEntryList.jobOrder !== "") ? tSEntry.jobOrder.id ===  parseInt(filterEntryList.jobOrder,10) : tSEntry )
                                .filter(tSEntry => (filterEntryList.jobOrderOwner && filterEntryList.jobOrderOwner !== "") ? tSEntry.jobOrder.jobOrderOwner.id ===  parseInt(filterEntryList.jobOrderOwner,10) : tSEntry )
                                .filter(tSEntry => (filterEntryList.employee && filterEntryList.employee !== "") ? tSEntry.employee.id ===  parseInt(filterEntryList.employee,10) : tSEntry )
                                .filter(tSEntry => (filterEntryList.status && filterEntryList.status.toString() !== "") ? tSEntry.status.toString() ===  filterEntryList.status : tSEntry )
                                .sort((a,b)=> a.day >= b.day ? 1 : -1) : [];
                               

  const tsEntryFirterTimeSelect=() =>{
    let monthInt="";
    if(yearSelect!==yearTemp|| monthSelect!==monthTemp){
      yearTemp=yearSelect;
      monthTemp=monthSelect;
      for (let index = 0; index < months.length; index++) {
        if(monthSelect===months[index])
          monthInt=index.toString()
      }
      props.getEntitiesMese(yearSelect,monthInt);
    }
  }

  const tsEntryFirterMonthSelect=(e: any) =>{
    const { target } = e;
    monthSelect=target.value!=null?target.value:"";
    tsEntryFirterTimeSelect();
  }

  const tsEntryFirterYearSelect=(e: any) =>{
    const { target } = e;
    yearSelect=target.value!=null?target.value:"";
    tsEntryFirterTimeSelect();
  }
  


  return (
    <div>
      <ModalBlockRejections show={blockRejectionsModal} classname={''}/>
      <Row className="justify-content-left">
      <Col md="12">
      <h2 id="ts-entry-heading">
        <Translate contentKey="ordersManagementApp.tSEntry.home.title">TimeSheet Entries</Translate> - {converterMonth(monthSelect).toLocaleUpperCase()}
        {authorities.includes("ROLE_ADMIN") ? (
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="ordersManagementApp.tSEntry.home.createLabel">Create new TS Entry</Translate>
        </Link>) :('') }
        </h2>
        </Col>
      </Row>
      <div>
      
      
      <Row className="justify-content-left">
      <Col sm="3" lg="2">
        <ButtonGroup vertical className="full-w">
        <div className="justify-content-center full-w">
          <Button  className="btn-light-primary" color="primary" block onClick={()=> {props.addAllSelectedEntity(filteredTSEntriesList.filter(e => e.status.toString() === "NOT_VERIFIED"))}}>
          <FontAwesomeIcon icon="check-square" />{' '}
                <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.selectall">Select All</Translate>
                </span>
          </Button>
          <Button className="btn-light-secondary" color="secondary" block onClick={()=> {props.removeAllSelectedEntity(selectedEntryList)}}>
          <FontAwesomeIcon icon="square" />{' '}
                <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.unselectall">Unselect All</Translate>
                </span>
          </Button>
          <Button className="btn-light-success" color="success"  block onClick = {()=> processEntries(filteredTSEntriesList, true)} >
          <FontAwesomeIcon icon="thumbs-up" />{' '}
                <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.approveselected">Approve Selected</Translate>
                </span>
          </Button>
          <Button className="btn-light-danger"  color="danger" block onClick = {()=> processEntries(filteredTSEntriesList, false)}>
          <FontAwesomeIcon icon="thumbs-down" />{' '}
                <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.rejectselected">Reject Selected</Translate>
                </span>
          </Button>
          <Button className="btn-light-info" color="info" block onClick={()=> {resetAllFilters()}}>
          <FontAwesomeIcon icon="times" />{' '}
                <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.resetfilters">Reset Filters</Translate>
                </span>
                </Button>
                </div>
        </ButtonGroup>    
          </Col>
          

          <Col sm="9" lg="10">



            <Row>
              
              <Col md="12" lg ="6" >
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm>
              <Row className="justify-content-center">
                <Col md="6">
                <AvGroup>
                  <Label for="ts-entry-employee">
                    <Translate contentKey="ordersManagementApp.tSEntry.year">Year</Translate>
                  </Label>
                  <AvInput id="ts-entry-employee" type="select" className="form-control" name="employee" value={yearSelect} onChange ={(e) => { tsEntryFirterYearSelect(e)}} >
                            {years.map((key) => (
                                <option value={key} key={key}>
                                  {key}
                                </option>
                              ))}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                <Label for="ts-entry-employee">
                  <Translate contentKey="ordersManagementApp.tSEntry.employee">Employee</Translate>
                </Label>
                <AvInput id="ts-entry-employee" type="select" className="form-control" name="employee" value={filterEntryList.employee} onChange ={(e) => { updateFilters( e )}} >
                  <option value="" key="0" />
                  {tSEntryList
                    ? _.uniqBy(filteredTSEntriesList
                      .map(tSEntry => tSEntry.employee),"id")
                      .sort((a,b)=>a.lastName >= b.lastName ? 1:-1)
                    .map(otherEntity =>(
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.firstName}  {otherEntity.lastName}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
                  <AvGroup>
                    <Label id="filterStatusLabel" for="ts-entry-filter-status">
                      <Translate contentKey="ordersManagementApp.tSEntry.filters.filterstatus">Status</Translate>
                    </Label>

                      <AvInput id="ts-entry-status" type="select" className="form-control" value={filterEntryList.status} name="status"  onChange ={(e) => { updateFilters( e )}} >
                    <option value="" key="0" />
                  { 
                  tSEntryList
                    ? _.uniqBy(filteredTSEntriesList
                        .map(tSEntry => tSEntry),"status")
                        .map(otherEntity => (
                        <option value={otherEntity.status} key={otherEntity.status}>
                          {converterStatus(otherEntity.status)}
                        </option>
                      ))
                    : null}
                    </AvInput>
                  </AvGroup>
              </Col>
              <Col md="6">
              <AvGroup>
                  <Label for="ts-entry-employee">
                    <Translate contentKey="ordersManagementApp.tSEntry.month.title">Month</Translate>
                  </Label>
                  <AvInput id="ts-entry-employee" type="select" className="form-control" name="employee" value={monthSelect} onChange ={(e) => { tsEntryFirterMonthSelect(e)}} >
                            {months.map((key) => (
                                <option value={key} key={key}>
                                  {converterMonth(key)}
                                </option>
                              ))}
                  </AvInput>
                </AvGroup>
              <AvGroup>
                <Label for="ts-entry-jobOrder">
                  <Translate contentKey="ordersManagementApp.tSEntry.jobOrder">Job Order</Translate>
                </Label>
                <AvInput id="ts-entry-jobOrder" type="select" className="form-control" value={filterEntryList.jobOrder} name="jobOrder" onChange ={(e) => { updateFilters( e )}} >
                  <option value="" key="0" />
                  { 
                  tSEntryList
                    ? _.uniqBy(filteredTSEntriesList
                        .map(tSEntry => tSEntry.jobOrder),"id")
                        .sort((a,b)=>a.jobOrderName >= b.jobOrderName ? 1:-1)
                        .map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.jobOrderName}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="ts-entry-jobOrderOwner">
                  <Translate contentKey="ordersManagementApp.tSEntry.jobOrderOwner">Job Order Owner</Translate>
                </Label>
                <AvInput id="ts-entry-jobOrderOwner" type="select" className="form-control" name="jobOrderOwner" value={filterEntryList.jobOrderOwner} onChange ={(e) => { updateFilters( e )}}>
                  <option value="" key="0" />
                  { 
                  tSEntryList
                    ? _.uniqBy(filteredTSEntriesList
                        .map(tSEntry => tSEntry.jobOrder.jobOrderOwner),"id")
                        .sort((a,b)=>a.surname >= b.surname ? 1:-1)
                        .map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name} {otherEntity.surname}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              </Col>
              </Row>
            </AvForm>
          )}
              </Col>
              


              <Col md="12" lg ="6" >
        {loading ? (
            <p>Loading...</p>
          ) : (
            
              <CardColumns className="ts-badge">
                <Card body color="warning">
                  <CardBody>
                    <CardText>



                            <div className="p-1">
                              <div className=" h5 text-warning text-uppercase pt-2">
                                <Label id="cards-toApprove" >
                                  <Translate contentKey="ordersManagementApp.tSEntry.cards.toapprove">To Approve</Translate>
                                </Label>
                              </div>
                            </div>
                            
                            <div className="p-1">
                              <h5 className="font-weight-bold display-4 mb-0 mt-0 text-black text-warning pt-0">
                              { filteredTSEntriesList ?  filteredTSEntriesList.filter(tSEntry => tSEntry.status.toString() === "NOT_VERIFIED" ).length : 0}
                              </h5>
                                <strong className="text-muted h5 text-uppercase">Entries</strong>
                            </div>
                            <div className="p-1">
                             <h6>
                                <Badge color="warning">
                      { filteredTSEntriesList ?  filteredTSEntriesList.filter(tSEntry => tSEntry.status.toString() === "NOT_VERIFIED" )
                      .reduce((acc, entry) => acc + entry.hours,0) : 0} ore Totali 
                                </Badge></h6>
                            </div>
                            

                    </CardText>
                  </CardBody>
                </Card>
                <Card body color="success">
                  <CardBody>
                    <CardText>
                            <div className="p-1">
                              <div className=" h5 text-success text-uppercase pt-2">
                                <Label id="cards-approved" >
                                  <Translate contentKey="ordersManagementApp.tSEntry.cards.approved">Approved</Translate>
                                </Label>
                              </div>
                            </div>
                            
                            <div className="p-1">
                              <h5 className="font-weight-bold display-4 mb-0 mt-0 text-black text-success pt-0">
                              { filteredTSEntriesList ?  filteredTSEntriesList.filter(tSEntry => tSEntry.status.toString() === "APPROVED" ).length : 0}
                              </h5>
                                <strong className="text-muted h5 text-uppercase">Entries</strong>
                            </div>
                            <div className="p-1">
                             <h6>
                            <Badge color="success">
                      { filteredTSEntriesList ?  filteredTSEntriesList.filter(tSEntry => tSEntry.status.toString() === "APPROVED" )
                      .reduce((acc, entry) => acc + entry.hours,0) : 0} ore Totali </Badge></h6>
                      </div>

                    </CardText>
                  </CardBody>
                </Card>
              <Card body color="danger">
              <CardBody>
                          <CardText>

                          <div className="p-1">
                              <div className=" h5 text-danger text-uppercase pt-2">
                                <Label id="cards-rejected" >
                                  <Translate contentKey="ordersManagementApp.tSEntry.cards.rejected">Rejected</Translate>
                                </Label>
                              </div>
                            </div>
                            
                            <div className="p-1">
                              <h5 className="font-weight-bold display-4 mb-0 mt-0 text-black text-danger pt-0">
                              { filteredTSEntriesList ?  filteredTSEntriesList.filter(tSEntry => tSEntry.status.toString() === "REJECTED" ).length : 0}
                              </h5>
                                <strong className="text-muted h5 text-uppercase">Entries</strong>
                            </div>
                            <div className="p-1">
                             <h6>
                                <Badge color="danger">
                      { filteredTSEntriesList ?  filteredTSEntriesList.filter(tSEntry => tSEntry.status.toString() === "REJECTED" )
                      .reduce((acc, entry) => acc + entry.hours,0) : 0} ore Totali
                                </Badge></h6>
                            </div>
                            


                    </CardText>
                  </CardBody>
                </Card>
          </CardColumns>

          )}

        </Col>




          </Row>

          </Col>



      </Row>
      </div>
      <Card className="mt-2">
        <CardBody>

      <div className="table-responsive">
     
        {tSEntryList && tSEntryList.length > 0 && filteredTSEntriesList.length>0? (
          <div>
          <div className='row'>
            <div className='col 6'> 
              <CSVLink
                  filename={"TSCEntries_"+monthSelect+".csv"}
                  data={getExsport(filteredTSEntriesList)}
                  className="btn btn-primary marginBottom"
                >
                  Download csv
              </CSVLink>     
              </div>    
            {
              isAdmin?
              <div className='col 6 rightPadding'>
                <Button
                className="btn btn-primary marginBottom " disabled={isDisable} onClick={() => syncEntryTsc()} color="primary"> 
                {isDisable && (
                <span className=" spinner-grow spinner-grow-sm"></span>
                )}
                Update Tsc
                </Button>
                </div>
                :<div></div>
            }
            </div> 
            

              <Table responsive>
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <Translate contentKey="ordersManagementApp.tSEntry.day">Day</Translate>
                    </th>
                    <th>
                      <Translate contentKey="ordersManagementApp.tSEntry.hours">Hours</Translate>
                    </th>
                    <th>
                      <Translate contentKey="ordersManagementApp.tSEntry.status">Status</Translate>
                    </th>
                    <th>
                      <Translate contentKey="ordersManagementApp.tSEntry.employee">Employee</Translate>
                    </th>
                    <th>
                      <Translate contentKey="ordersManagementApp.tSEntry.jobOrder">Job Order</Translate>
                    </th>
                    <th>
                      <Translate contentKey="ordersManagementApp.tSEntry.dettaglio">Details</Translate>
                    </th>
                    <th>
                      <Translate contentKey="ordersManagementApp.tSEntry.jobOrderOwner">Job Order Owner</Translate>
                    </th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {filteredTSEntriesList.map((tSEntry, i) => (
                  
                      <tr key={`entity-${i}`}>
                        <td>
                        <FormGroup className="mx-2" check>                            
                          <Label check>
                            <Input type="checkbox" className="syleCheck"
                                checked={
                                  selectedEntryList.map(e => e.id).includes(tSEntry.id)
                                } 
                                onClick={ (e)=>{
                                  updateSelectedList(tSEntry,e.target.checked) 
                                  }}  disabled={tSEntry.status.toString() !== 'NOT_VERIFIED' } />
                          </Label>
                        </FormGroup>
                        </td>
                        <td>
                          <TextFormat type="date" value={tSEntry.day} format={APP_LOCAL_DATE_FORMAT} />
                        </td>
                        <td>{tSEntry.hours}</td>
                        <td>
                          { 
                            getStatusIcon(tSEntry.status)          
                          }
                        </td>
                        <td>{tSEntry.employee ? (authorities.includes("ROLE_ADMIN") ? (<Link to={`employee/${tSEntry.employee.id}`}>{tSEntry.employee.firstName} {tSEntry.employee.lastName}</Link>) : ( <strong> {tSEntry.employee.firstName + ' ' + tSEntry.employee.lastName} </strong> )) : ''}</td>
                        <td>{tSEntry.jobOrder ? (authorities.includes("ROLE_ADMIN") 
                        ? <Link to={`job-order/${tSEntry.jobOrder.id}`}>{tSEntry.jobOrder.jobOrderName}</Link>: (<strong> {tSEntry.jobOrder.jobOrderName}</strong>)) : ''}</td>
                        <td>
                          { 
                            getDettaglio(tSEntry.dettaglio)
                          }
                        </td>
                        <td>{
                              tSEntry.jobOrder ?   
                                  (tSEntry.jobOrder.jobOrderOwner ? 
                                      (authorities.includes("ROLE_ADMIN") ? 
                                          (<Link to={`order-owner/${tSEntry.jobOrder.jobOrderOwner}`}>{tSEntry.jobOrder.jobOrderOwner.surname}</Link>):
                                          (<strong> { tSEntry.jobOrder.jobOrderOwner.surname} </strong>)) :
                                      <Translate contentKey="ordersManagementApp.tSEntry.noOrderOwnerAssigned">No OrderOwner Assigned</Translate>):
                              <Translate contentKey="ordersManagementApp.tSEntry.noOrderAssigned">No Order Assigned</Translate>
                        }</td>

                        <td className="text-right">
                          <div className="btn-group flex-btn-group-container">
                            <Button className="btn-light-success" onClick={() => processEntry(tSEntry,true,0)} color="success" size="sm" disabled={tSEntry.status.toString() !== 'NOT_VERIFIED' }>
                              <FontAwesomeIcon icon="thumbs-up" />{' '}
                            </Button>
                            <Button className="btn-light-danger" onClick={() => processEntry(tSEntry,false,1)} color="danger" size="sm" disabled={tSEntry.status.toString() !== 'NOT_VERIFIED' }>
                              <FontAwesomeIcon icon="thumbs-down" />{' '}
                            </Button>

                          </div>
                        </td>
                        { authorities.includes("ROLE_ADMIN") ? (
                        <td className="text-right">
                          <div className="btn-group flex-btn-group-container">
                            <Button tag={Link} to={`${match.url}/${tSEntry.id}`} color="info" size="sm">
                              <FontAwesomeIcon icon="eye" />{' '}
                            </Button>
                            <Button tag={Link} to={`${match.url}/${tSEntry.id}/edit`} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />{' '}
                            </Button>
                            <Button tag={Link} to={`${match.url}/${tSEntry.id}/delete`} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />{' '}
                            </Button>
                          </div>
                        </td>) : (<td></td>)}
                      </tr>

                  ))}
                </tbody>
              </Table>
          </div>
          
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="ordersManagementApp.tSEntry.home.notFound">No TS Entries found</Translate>
            </div>
          )
        )}
          </div>
        </CardBody>
      </Card>

    </div>
  );
};

const mapStateToProps = ({ tSEntry,authentication,locale }: IRootState) => ({
  tSEntryList: tSEntry.entities,
  blockRejectionsModal:tSEntry.blockRejectionsModal,
  loading: tSEntry.loading,
  authorities: authentication.account.authorities,
  visibleJobOrders :authentication.account.visibleJobOrders,
  rejectedEntryList : tSEntry.rejectedEntryList,
  approvedEntryList : tSEntry.approvedEntryList,
  selectedEntryList : tSEntry.selectedEntryList,
  filterEntryList : tSEntry.filterEntryList,
  currentLocale : locale.currentLocale
});

const mapDispatchToProps = {
  getEntities,
  updateEntity,
  addRejectedEntity,
  addApprovedEntity,
  addSelectedEntity,
  removeSelectedEntity,
  setFilterEntryList,
  resetFilterEntryList,
  removeAllSelectedEntity,
  addAllSelectedEntity,
  toggleBlockRejectionsModal,
  syncTsc,
  pingEmail,
  getEntitiesMese
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TSEntry);


