import { IUser } from 'app/shared/model/user.model';
import { IJobOrder } from 'app/shared/model/job-order.model';
import { IBusinessUnit } from 'app/shared/model/business-unit.model';

export interface IJobOrderOwner {
  id?: number;
  name?: string;
  surname?: string;
  user?: IUser;
  jobOrders?: IJobOrder[];
  businessUnit?: IBusinessUnit;
}

export const defaultValue: Readonly<IJobOrderOwner> = {};
