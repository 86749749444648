import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import JobOrder from './job-order';
import JobOrderDetail from './job-order-detail';
import JobOrderUpdate from './job-order-update';
import JobOrderDeleteDialog from './job-order-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={JobOrderDeleteDialog} />
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={JobOrderUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={JobOrderUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={JobOrderDetail} />
      <ErrorBoundaryRoute path={match.url} component={JobOrder} />
    </Switch>
  </>
);

export default Routes;
