import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import JobOrder from './job-order';
import JobOrderOwner from './job-order-owner';
import TSEntry from './ts-entry';
import BusinessUnit from './business-unit';
import Employee from './employee';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}job-order`} component={JobOrder} />
      <ErrorBoundaryRoute path={`${match.url}job-order-owner`} component={JobOrderOwner} />
      <ErrorBoundaryRoute path={`${match.url}ts-entry`} component={TSEntry} />
      <ErrorBoundaryRoute path={`${match.url}business-unit`} component={BusinessUnit} />
      <ErrorBoundaryRoute path={`${match.url}employee`} component={Employee} />
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
