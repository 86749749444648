import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './ts-entry.reducer';
import { ITSEntry } from 'app/shared/model/ts-entry.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ITSEntryDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const TSEntryDetail = (props: ITSEntryDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);
  
  const { tSEntryEntity } = props;
  let dettaglio ;
  if(tSEntryEntity.dettaglio!=null && tSEntryEntity.dettaglio!=="") 
    dettaglio=
    <div>
      <dt>
      <span id="dettaglio">
        <Translate contentKey="ordersManagementApp.tSEntry.dettaglio">dettaglio</Translate>
      </span>
      </dt>
      <dd>{tSEntryEntity.dettaglio}</dd>
    </div>
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="ordersManagementApp.tSEntry.detail.title">TSEntry</Translate> [<b>{tSEntryEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="timestamp">
              <Translate contentKey="ordersManagementApp.tSEntry.timestamp">Timestamp</Translate>
            </span>
          </dt>
          <dd>
            <TextFormat value={tSEntryEntity.timestamp} type="date" format={APP_DATE_FORMAT} />
          </dd>
          <dt>
            <span id="day">
              <Translate contentKey="ordersManagementApp.tSEntry.day">Day</Translate>
            </span>
          </dt>
          <dd>
            <TextFormat value={tSEntryEntity.day} type="date" format={APP_LOCAL_DATE_FORMAT} />
          </dd>
          <dt>
            <span id="hours">
              <Translate contentKey="ordersManagementApp.tSEntry.hours">Hours</Translate>
            </span>
          </dt>
          <dd>{tSEntryEntity.hours}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="ordersManagementApp.tSEntry.status">Status</Translate>
            </span>
          </dt>
          <dd>{tSEntryEntity.status}</dd>
          {dettaglio}
          <dt>
            <Translate contentKey="ordersManagementApp.tSEntry.employee">Employee</Translate>
          </dt>
          <dd>{tSEntryEntity.employee ? tSEntryEntity.employee.firstName + ' ' + tSEntryEntity.employee.lastName : ''}</dd>
          <dt>
            <Translate contentKey="ordersManagementApp.tSEntry.jobOrder">Job Order</Translate>
          </dt>
          <dd>{tSEntryEntity.jobOrder ? tSEntryEntity.jobOrder.jobOrderName : ''}</dd>
        </dl>
        <Button tag={Link} to="/ts-entry" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/ts-entry/${tSEntryEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ tSEntry }: IRootState) => ({
  tSEntryEntity: tSEntry.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TSEntryDetail);
