import { Moment } from 'moment';
import { ITSEntry } from 'app/shared/model/ts-entry.model';
import { IJobOrderOwner } from 'app/shared/model/job-order-owner.model';

export interface IJobOrder {
  id?: number;
  jobOrderName?: string;
  jobOrderCode?: string;
  startDate?: Moment;
  endDate?: Moment;
  tsEntries?: ITSEntry[];
  jobOrderOwner?: IJobOrderOwner;
}

export const defaultValue: Readonly<IJobOrder> = {};
