import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IEmployee } from 'app/shared/model/employee.model';
import { getEntities as getEmployees } from 'app/entities/employee/employee.reducer';
import { IJobOrder } from 'app/shared/model/job-order.model';
import { getEntities as getJobOrders } from 'app/entities/job-order/job-order.reducer';
import { getEntity, updateEntity, createEntity, reset } from './ts-entry.reducer';
import { ITSEntry } from 'app/shared/model/ts-entry.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ITSEntryUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const TSEntryUpdate = (props: ITSEntryUpdateProps) => {
  const [employeeId, setEmployeeId] = useState('0');
  const [jobOrderId, setJobOrderId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { tSEntryEntity, employees, jobOrders, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/ts-entry');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getEmployees();
    props.getJobOrders();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    values.timestamp = convertDateTimeToServer(values.timestamp);

    if (errors.length === 0) {
      const entity = {
        ...tSEntryEntity,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="ordersManagementApp.tSEntry.home.createOrEditLabel">
            <Translate contentKey="ordersManagementApp.tSEntry.home.createOrEditLabel">Create or edit a TSEntry</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : tSEntryEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="ts-entry-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="ts-entry-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="timestampLabel" for="ts-entry-timestamp">
                  <Translate contentKey="ordersManagementApp.tSEntry.timestamp">Timestamp</Translate>
                </Label>
                <AvInput
                  id="ts-entry-timestamp"
                  type="datetime-local"
                  className="form-control"
                  name="timestamp"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.tSEntryEntity.timestamp)}
                />
              </AvGroup>
              <AvGroup>
                <Label id="dayLabel" for="ts-entry-day">
                  <Translate contentKey="ordersManagementApp.tSEntry.day">Day</Translate>
                </Label>
                <AvField id="ts-entry-day" type="date" className="form-control" name="day" />
              </AvGroup>
              <AvGroup>
                <Label id="hoursLabel" for="ts-entry-hours">
                  <Translate contentKey="ordersManagementApp.tSEntry.hours">Hours</Translate>
                </Label>
                <AvField
                  id="ts-entry-hours"
                  type="string"
                  className="form-control"
                  name="hours"
                  validate={{
                    min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                    max: { value: 12, errorMessage: translate('entity.validation.max', { max: 12 }) },
                    number: { value: true, errorMessage: translate('entity.validation.number') }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="statusLabel" for="ts-entry-status">
                  <Translate contentKey="ordersManagementApp.tSEntry.status">Status</Translate>
                </Label>
                <AvInput
                  id="ts-entry-status"
                  type="select"
                  className="form-control"
                  name="status"
                  value={(!isNew && tSEntryEntity.status) || 'NOT_VERIFIED'}
                >
                  <option value="NOT_VERIFIED">{translate('ordersManagementApp.TSEntryStatus.NOT_VERIFIED')}</option>
                  <option value="APPROVED">{translate('ordersManagementApp.TSEntryStatus.APPROVED')}</option>
                  <option value="REJECTED">{translate('ordersManagementApp.TSEntryStatus.REJECTED')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>

              <AvGroup>
                <Label id="dettaglioLabel" for="ts-entry-dettaglio">
                  <Translate contentKey="ordersManagementApp.tSEntry.dettaglio">Dettaglio</Translate>
                </Label>
                <AvField
                  id="ts-entry-dettaglio"
                  type="textarea"
                  className="form-control"
                  name="dettaglio"
                />
              </AvGroup>
                <Label for="ts-entry-employee">
                  <Translate contentKey="ordersManagementApp.tSEntry.employee">Employee</Translate>
                </Label>
                <AvInput id="ts-entry-employee" type="select" className="form-control" name="employee.id">
                  <option value="" key="0" />
                  {employees
                    ? employees.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.firstName} {otherEntity.lastName}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="ts-entry-jobOrder">
                  <Translate contentKey="ordersManagementApp.tSEntry.jobOrder">Job Order</Translate>
                </Label>
                <AvInput id="ts-entry-jobOrder" type="select" className="form-control" name="jobOrder.id">
                  <option value="" key="0" />
                  {jobOrders
                    ? jobOrders.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.jobOrderName}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/ts-entry" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  employees: storeState.employee.entities,
  jobOrders: storeState.jobOrder.entities,
  tSEntryEntity: storeState.tSEntry.entity,
  loading: storeState.tSEntry.loading,
  updating: storeState.tSEntry.updating,
  updateSuccess: storeState.tSEntry.updateSuccess
});

const mapDispatchToProps = {
  getEmployees,
  getJobOrders,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TSEntryUpdate);
