import { Moment } from 'moment';
import { IEmployee } from 'app/shared/model/employee.model';
import { IJobOrder } from 'app/shared/model/job-order.model';
import { TSEntryStatus } from 'app/shared/model/enumerations/ts-entry-status.model';
import { IJobOrderOwner } from './job-order-owner.model';

export interface ITSEntry {
  id?: number;
  timestamp?: Moment;
  day?: Moment;
  hours?: number;
  status?: TSEntryStatus;
  employee?: IEmployee;
  jobOrder?: IJobOrder;
  dettaglio?: string;
}

export interface ITSEntryFilter {
  dateFrom?: Moment;
  dateTo?: Moment;
  status?: string;
  employee?: string;
  jobOrder?: string;
  jobOrderOwner?: string;
  dettaglio?: string;
}

export interface ITSEntryExsport {
  giorno?: string;
  ore?: number;
  dipendente?: string;
  codiceCommessa?: string;
  nomeCommessa?: string;
  dettaglio?: string;
  responsabile?: string;
  status?: TSEntryStatus;
}

export const defaultValue: Readonly<ITSEntry> = {};
